import { useContext, useEffect } from 'react';
import { AppCtx } from 'pages/_app';
import mixpanel from 'mixpanel-browser';
import { useCustomerContext } from '@/lib/customer/CustomerContext';
import { logger } from '@/lib/logger';

export default function MixpanelAnalytics() {
  const { customer } = useCustomerContext();
  const { isMixPanelReady } = useContext(AppCtx);

  useEffect(() => {
    logger.info(
      { customer, isMixPanelReady },
      'handling mixpanel analytics for customer',
    );

    async function identifyMixpanelUser() {
      if (!isMixPanelReady || !customer) return;

      mixpanel.identify(customer.shopifyId);

      mixpanel.people.set_once({
        '# of Searches': 0,
        $shopify_id: customer.shopifyId,
        $createdAt: customer.createdAt,
      });

      mixpanel.people.set({
        $name: `${customer.firstName} ${customer.lastName}`,
        $first_name: customer.firstName,
        $last_name: customer.lastName,
        $email: customer.email,
      });
    }

    identifyMixpanelUser();
  }, [isMixPanelReady, customer]);

  useEffect(() => {
    logger.info({ isMixPanelReady }, 'handling mixpanel registration');

    async function registerMixpanel() {
      if (!isMixPanelReady) return;

      const query = new URLSearchParams(window.location.search);
      const utm_source = query.get('utm_source');
      const utm_medium = query.get('utm_medium');
      const utm_campaign = query.get('utm_campaign');
      const utm_term = query.get('utm_term');
      const mixpanelUtmSource = mixpanel.get_property('UTM Source');
      const returningUtmSource = sessionStorage.getItem('returning_utm_source');

      if (
        returningUtmSource &&
        mixpanelUtmSource &&
        !utm_source &&
        !utm_medium &&
        !utm_campaign &&
        !utm_term
      )
        mixpanel.register({ 'UTM Source': null });
      else if (utm_source)
        sessionStorage.setItem('returning_utm_source', utm_source);
    }

    registerMixpanel();
  }, [isMixPanelReady]);

  return null;
}
