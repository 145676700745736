export enum BrazeEvent {
  ADD_TO_CART = 'Product_Added_to_Cart',
}

export enum SegmentEvent {
  // https://segment.com/docs/connections/spec/ecommerce/v2/
  PRODUCT_CLICKED = 'Product Clicked',
  PRODUCT_VIEWED = 'Product Viewed',
  PRODUCT_ADDED = 'Product Added',
  PRODUCT_REMOVED = 'Product Removed',
  PRODUCT_ADDED_WISHLIST = 'Product Added to Wishlist',
  PRODUCT_REMOVED_WISHLIST = 'Product Removed from Wishlist',
  PRODUCT_LIST_VIEWED = 'Product List Viewed',
  PRODUCT_LIST_FILTERED = 'Product List Filtered',
  PRODUCTS_SEARCHED = 'Products Searched',
  CART_VIEWED = 'Cart Viewed',

  // Checkout Events
  CHECKOUT_STARTED = 'Checkout Started',
  CHECKOUT_SHIPPING_INFO_ENTERED = 'Shipping Info Entered',
  CHECKOUT_BILLING_INFO_ENTERED = 'Billing Info Entered',
  CHECKOUT_PAYMENT_INFO_ENTERED = 'Payment Info Entered',
  CHECKOUT_PAYMENT_SUBMITTED = 'Payment Submitted',
  CHECKOUT_DISCOUNT_APPLIED = 'Discount Applied',
  CHECKOUT_DISCOUNT_REMOVED = 'Discount Removed',
  CHECKOUT_COMPLETED = 'Checkout Completed',

  // https://segment.com/docs/connections/spec/b2b-saas/
  SIGNUP = 'Signed Up',
  LOGIN = 'Signed In',
  LOGOUT = 'Signed Out',

  // Custom Reebelo Events
  PRODUCT_FASTER_DELIVERY_VIEWED = 'Product Faster Delivery Viewed',
  SUBSCRIPTION_PREFERENCES_UPDATED = 'Subscription Preferences Updated',
  SURVEY_COMPLETED = 'Survey Completed',

  // Buyback
  BUYBACK_CREATED = 'Buyback Created',
  BUYBACK_PAYMENT_ADDED = 'Buyback Payment Added',
  BUYBACK_SHIPPING_ADDED = 'Buyback Shipping Added',
  BUYBACK_ACCEPTED = 'Buyback Accepted',
  BUYBACK_REJECTED = 'Buyback Rejected',
  BUYBACK_OFFERS_GENERATED = 'Buyback Offers Generated',
}

export enum SurveyName {
  HOW_DID_YOU_HEAR_ABOUT_US = 'HOW_DID_YOU_HEAR_ABOUT_US',
  NPS = 'NPS',
  CSAT = 'CSAT',
}

export const segmentToBrazeEvent = (
  segmentEvent: SegmentEvent,
): BrazeEvent | undefined => {
  switch (segmentEvent) {
    case SegmentEvent.PRODUCT_ADDED:
      return BrazeEvent.ADD_TO_CART;
    default:
      return undefined;
  }
};
