/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { BrazeFeatureFlag } from '@lambda/commons/apis/shopify/types/storefront';
import { AppCtx } from 'pages/_app';
import { useCustomerContext } from '@/lib/customer/CustomerContext';
import { logger } from '@/lib/logger';
import {
  changeBrazeUser,
  getAllBrazeFeatureFlags,
  getBrazeContentCards,
  openBrazeSession,
  setBrazeEmail,
} from '@/components/commons/brazeUtils';

export default function BrazeAnalytics() {
  const { customer } = useCustomerContext();
  const { isMixPanelReady } = useContext(AppCtx);
  const [featureFlags, setFeatureFlags] = useState<BrazeFeatureFlag[] | null>(
    null,
  );
  const [contentCards, setContentCards] = useState<Record<string, any>>({});

  useEffect(() => {
    logger.info({ customer }, 'handling braze analytics for customer');

    async function identifyBrazeUser() {
      if (!customer) {
        openBrazeSession();

        return;
      }

      if (customer?.reebeloId) changeBrazeUser(customer.reebeloId);
      if (customer?.email) setBrazeEmail(customer.email);
    }

    async function setBrazeFeatureFlags() {
      const output = await getAllBrazeFeatureFlags();

      setFeatureFlags(output);
    }

    async function setBrazeContentCards() {
      getBrazeContentCards((event) => {
        if (!event.cards) return;

        const dictionary: Record<string, any> = {};

        event.cards.forEach((card: any) => {
          if (card.extras.site_location)
            dictionary[card.extras.site_location] = card;
        });

        setContentCards(dictionary);
      });
    }

    identifyBrazeUser();
    setBrazeFeatureFlags();
    setBrazeContentCards();
  }, [customer]);

  return null;
}
