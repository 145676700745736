import { ConfigT } from './validate';
import auConfig from './AU.json';
import nzConfig from './NZ.json';
import usConfig from './US.json';
import sgConfig from './SG.json';
import caConfig from './CA.json';
import devConfig from './DEV.json';

let config = devConfig;

if (
  process.env.NEXT_PUBLIC_STORE === 'reebelo-au' ||
  process.env.STORE === 'reebelo-au'
)
  config = auConfig as any;

if (
  process.env.NEXT_PUBLIC_STORE === 'reebelo-nz' ||
  process.env.STORE === 'reebelo-nz'
)
  config = nzConfig as any;

if (
  process.env.NEXT_PUBLIC_STORE === 'reebelo-us' ||
  process.env.STORE === 'reebelo-us'
)
  config = usConfig as any;

if (
  process.env.NEXT_PUBLIC_STORE === 'reebelo-ca' ||
  process.env.STORE === 'reebelo-ca'
)
  config = caConfig as any;

if (
  process.env.NEXT_PUBLIC_STORE === 'quista' ||
  process.env.STORE === 'quista'
)
  config = sgConfig as any;

if (
  process.env.NEXT_PUBLIC_STORE === 'reebelo-dev' ||
  process.env.STORE === 'reebelo-dev'
)
  config = devConfig as any;

if (Object.keys(config).length === 0) {
  throw Error(
    `No config is defined for store ${
      process.env.NEXT_PUBLIC_STORE || process.env.STORE
    }`,
  );
}

export const NON_TECH_CATEGORIES = [
  'Health & Beauty',
  'Skincare',
  'Makeup',
  'Hair Care',
  'Fragrance',
  'Health & Body',
  'Men - Health & Beauty',
  'Cosmetics',
  'Sports & Fitness',
  'Fitness',
  'Yoga Equipment',
  'Yoga Mats',
  'Yoga Accessories',
  'Cardio Equipment',
  'Fitness Equipment',
  'Outdoor Mats',
  'Yoga Bundles',
];

export const HEALTH_BEAUTY_CATEGORIES = [
  'Health & Beauty',
  'Skincare',
  'Makeup',
  'Hair Care',
  'Fragrance',
  'Health & Body',
  'Men - Health & Beauty',
  'Cosmetics',
];

export const POWER_TOOL_CATEGORIES = [
  'Power Tools',
  'Hardware Tools',
  'Power Saws',
  'Cordless drills',
  'Impact Wrenches',
  'Grinders',
  'Rotary Tools ',
  'Impact Drivers',
  'Nail & Staple Guns',
  'Air Tools & Air Compressors',
  'Chainsaws',
  'Pressure Washers',
  'Leaf Blowers and Vacuums',
  'String Trimmers',
  'Snow blowers',
  'Hedge Trimmers',
  'Gas Generators',
  'Electric Generators',
  'Flashlights',
  'Furniture Dollies & Castors',
  'Jacks, Stands, & Sawhorses',
  'Tool & Equipment Trolleys',
  'Wet & Dry Vacums',
  'Work Benches',
  'Batteries & Chargers',
  'Drill Sets',
  'Driver Set',
  'Power Stations',
  'Power Station bundles',
  'Power Station Accessories',
  'Cutting Tools',
  'Files',
  'Hammers & Mallets',
  'Clamps & Vises',
  'Saws',
  'Screwdrivers & Nutdrivers',
];

export const SPORT_FITNESS_CATEGORIES = [
  'E-scooters',
  'E-skateboards',
  'Electric Bikes',
  'Hoverboard Bundle',
  'Hoverboards',
  'Fitness',
  'Fitness Equipment',
  'Sports & Fitness',
  'Sports & Fitness Bundle',
  'Yoga',
  'Bikes',
  'Binoculars',
  'Golf',
  'Pickleball',
  'Snowboarding',
  'Ski',
  'Tennis',
  'Hiking Clothing',
  'Hiking Shoes',
  'Cycling Clothing',
  'Tactical Clothing',
  'Golf Clothing',
  'Snowboarding Helmets',
  'Ski Helmets',
  'Ski and Snowboarding Jackets',
  'Sports Equipment',
  'Clothing and Gear',
];

export default config as ConfigT;
