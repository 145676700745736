import mixpanel from 'mixpanel-browser';
import { Customer } from '@lambda/apis/src/customer/types';
import {
  CustomerSubscriptionStatus,
  CustomerSubscriptionChannel,
  CustomerSubscriptionSource,
} from '@lambda/apis/src/customer/enum';
import { IdentifyCustomerRequest } from './types';
import {
  analytics,
  trackEvent,
  getDefaultProperties,
  shouldTrack,
} from './vendor/segment/segment';
import { logger } from '../logger';
import { setBrazeEmail } from '@/components/commons/brazeUtils';
import { SegmentEvent, SurveyName } from './events';

const getMixpanelId = (): string | undefined => {
  try {
    return mixpanel.get_distinct_id();
  } catch (error: any) {
    logger.error({ error }, 'error determining mixpanel id');

    return undefined;
  }
};

export const identify = (r: IdentifyCustomerRequest): void => {
  try {
    if (!shouldTrack()) {
      logger.info({ r }, 'not identifying customer in segment');

      return;
    }

    const properties = { ...getDefaultProperties(), ...r.properties };

    logger.info({ r }, 'identifying customer in segment');

    if (r.properties.email) setBrazeEmail(r.properties.email);

    analytics.identify(r.userId, properties);
  } catch (error: any) {
    logger.error({ r, error }, 'error identifying customer in segment');
  }
};

export const alias = (userId: string): void => {
  try {
    if (!shouldTrack()) {
      logger.info({ userId }, 'not aliasing customer in segment');

      return;
    }

    logger.info({ userId }, 'aliasing customer in segment');

    analytics.alias(userId);
  } catch (error: any) {
    logger.error({ userId, error }, 'error aliasing customer in segment');
  }
};

const trackCustomerEvent = (event: SegmentEvent, properties = {}) => {
  try {
    if (!shouldTrack()) {
      logger.info(
        { event, properties },
        'not tracking customer event in segment',
      );

      return;
    }

    logger.info({ event }, 'tracking customer event in segment');

    analytics.track(event, properties);
  } catch (error: any) {
    logger.error({ event, error }, 'error tracking customer event in segment');
  }
};

export const identifyCustomer = (customer: Customer): void =>
  identify({
    userId: customer.reebeloId,
    properties: {
      first_name: customer.firstName,
      last_name: customer.lastName,
      email: customer.email,
      reebelo_id: customer.reebeloId,
      shopify_id: customer.shopifyId,
      mixpanel_id: getMixpanelId(),
    },
  });

export const logoutCustomer = async (): Promise<void> => {
  try {
    logger.info('logging out customer from segment');
    await analytics.reset();
  } catch (error: any) {
    logger.error({ error }, 'error logging out customer from segment');
  }
};

export const trackSignup = () => trackCustomerEvent(SegmentEvent.SIGNUP);

export const trackLogin = () => trackCustomerEvent(SegmentEvent.LOGIN);

export const trackLogout = () => trackCustomerEvent(SegmentEvent.LOGOUT);

export const trackSubscriptionPreferencesUpdated = (
  key: string,
  status: CustomerSubscriptionStatus,
  channel: CustomerSubscriptionChannel,
  source?: CustomerSubscriptionSource,
) => {
  identify({
    properties: {
      email_subscribe: status,
      subscription_source: source,
    },
  });

  trackEvent(SegmentEvent.SUBSCRIPTION_PREFERENCES_UPDATED, {
    key,
    type: channel.toUpperCase(),
    status,
  });
};

export const trackSurveyCompleted = (
  surveyName: SurveyName,
  properties: object,
) => {
  trackCustomerEvent(SegmentEvent.SURVEY_COMPLETED, {
    surveyName,
    ...properties,
  });
};
