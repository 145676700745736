import {
  BuybackProduct,
  BuybackProductType,
} from '@lambda/apis/src/dynamoDb/types';
import { BuybackUSConfig } from '@/settings/configs/buyback/us/type';
import {
  ConfigT,
  DeviceCarriers,
  DeviceConditions,
  DeviceConnectivity,
  StreamlinedDeviceT,
  DeviceVariantT,
  PageType,
  VariantsT,
  DeviceT,
  SummaryQuoteT,
} from './types';
import attIcon from '@/public/icons/buyback/sell-device/att.svg';
import unlockedIcon from '@/public/icons/buyback/sell-device/unlocked.svg';
import verizonIcon from '@/public/icons/buyback/sell-device/verizon.svg';
import tmobileIcon from '@/public/icons/buyback/sell-device/t-mobile.svg';
import wifiIcon from '@/public/icons/buyback/sell-device/wifi.svg';
import cellularIcon from '@/public/icons/buyback/sell-device/cellular.svg';
import networkIcon from '@/public/icons/buyback/sell-device/network.svg';
import cpuIcon from '@/public/icons/buyback/sell-device/cpu.svg';
import ramIcon from '@/public/icons/buyback/sell-device/ram.svg';
import storageIcon from '@/public/icons/buyback/sell-device/storage.svg';
import watchCaseMaterialIcon from '@/public/icons/buyback/sell-device/watchCaseMaterial.svg';
import watchSizeIcon from '@/public/icons/buyback/sell-device/watchSize.svg';
// Screen
import phoneScreenFlawlessIcon from '@/public/icons/buyback/conditions/screen/phone-flawless.svg';
import phoneScreenMinorIcon from '@/public/icons/buyback/conditions/screen/phone-minor.svg';
import phoneScreenModerateIcon from '@/public/icons/buyback/conditions/screen/phone-moderate.svg';
import phoneScreenCrackedIcon from '@/public/icons/buyback/conditions/screen/phone-cracked.svg';
import tabletScreenFlawlessIcon from '@/public/icons/buyback/conditions/screen/tablet-flawless.svg';
import tabletScreenMinorIcon from '@/public/icons/buyback/conditions/screen/tablet-minor.svg';
import tabletScreenModerateIcon from '@/public/icons/buyback/conditions/screen/tablet-moderate.svg';
import tabletScreenCrackedIcon from '@/public/icons/buyback/conditions/screen/tablet-cracked.svg';
import watchScreenFlawlessIcon from '@/public/icons/buyback/conditions/screen/watch-flawless.svg';
import watchScreenMinorIcon from '@/public/icons/buyback/conditions/screen/watch-minor.svg';
import watchScreenModerateIcon from '@/public/icons/buyback/conditions/screen/watch-moderate.svg';
import watchScreenCrackedIcon from '@/public/icons/buyback/conditions/screen/watch-cracked.svg';
import laptopScreenFlawlessIcon from '@/public/icons/buyback/conditions/screen/laptop-flawless.svg';
import laptopScreenMinorIcon from '@/public/icons/buyback/conditions/screen/laptop-minor.svg';
import laptopScreenModerateIcon from '@/public/icons/buyback/conditions/screen/laptop-moderate.svg';
import laptopScreenCrackedIcon from '@/public/icons/buyback/conditions/screen/laptop-cracked.svg';
// Back
import phoneBackFlawlessIcon from '@/public/icons/buyback/conditions/back/phone-flawless.svg';
import phoneBackMinorIcon from '@/public/icons/buyback/conditions/back/phone-minor.svg';
import phoneBackModerateIcon from '@/public/icons/buyback/conditions/back/phone-moderate.svg';
import phoneBackCrackedIcon from '@/public/icons/buyback/conditions/back/phone-cracked.svg';
import tabletBackFlawlessIcon from '@/public/icons/buyback/conditions/back/tablet-flawless.svg';
import tabletBackMinorIcon from '@/public/icons/buyback/conditions/back/tablet-minor.svg';
import tabletBackModerateIcon from '@/public/icons/buyback/conditions/back/tablet-moderate.svg';
import tabletBackCrackedIcon from '@/public/icons/buyback/conditions/back/tablet-cracked.svg';
import watchBackFlawlessIcon from '@/public/icons/buyback/conditions/back/watch-flawless.svg';
import watchBackMinorIcon from '@/public/icons/buyback/conditions/back/watch-minor.svg';
import watchBackModerateIcon from '@/public/icons/buyback/conditions/back/watch-moderate.svg';
import watchBackCrackedIcon from '@/public/icons/buyback/conditions/back/watch-cracked.svg';
import laptopBackFlawlessIcon from '@/public/icons/buyback/conditions/back/laptop-flawless.svg';
import laptopBackMinorIcon from '@/public/icons/buyback/conditions/back/laptop-minor.svg';
import laptopBackModerateIcon from '@/public/icons/buyback/conditions/back/laptop-moderate.svg';
import laptopBackCrackedIcon from '@/public/icons/buyback/conditions/back/laptop-cracked.svg';
import { COMMON_VARIANTS, MAIN_STEPS, MINIMUM_SUB_STEPS } from './constants';

// Function to find an object by handle and return its nested level
export function findObjectByHandle(
  configs: BuybackUSConfig[],
  targetHandle: string,
  currentLevel = 0,
): ConfigT | null {
  const foundConfig = configs.find((config) => config.handle === targetHandle);

  if (foundConfig) return { device: foundConfig, step: currentLevel };

  let result: ConfigT | null = null;

  configs.forEach((config) => {
    if (config.content && Array.isArray(config.content)) {
      const nestedResult = findObjectByHandle(
        config.content as BuybackUSConfig[],
        targetHandle,
        currentLevel + 1,
      );

      if (nestedResult?.device) result = nestedResult;
    }
  });

  return result;
}

export const generatePageType = (step: number) => {
  if (step === 0) return PageType.NAVIGATION;
  if (step === 1) return PageType.BRAND;
  if (step === 2) return PageType.MODEL;

  return PageType.VARIANT;
};

export const generateSelectDeviceTitle = (type: PageType) => {
  switch (type) {
    case PageType.NAVIGATION:
      return 'What are you selling?';
    case PageType.BRAND:
      return 'Which brand is it?';
    case PageType.MODEL:
      return 'Which model are you selling?';
    default:
      return '';
  }
};

export const generateSelectVariantTitle = (
  variant: VariantsT,
  device: string,
  category: BuybackProductType | undefined,
) => {
  switch (variant) {
    case 'carrier':
      return `What is your ${device} carrier?`;
    case 'connectivity':
      return `What is your ${device} connectivity?`;
    case 'cpu':
      return `What is your ${device} CPU?`;
    case 'ram':
      return `What is your ${device} RAM?`;
    case 'storage':
      if (category === 'watch') return `What is your ${device} size?`;

      return `What is your ${device} storage capacity?`;
    case 'watchCaseMaterial':
      return `What is your ${device} case material?`;
    case 'screen':
      return `What does the screen look like?`;
    case 'housing':
      return `What do the back and corners look like?`;
    case 'condition':
      return `Is it in good working condition?`;
    default:
      return '';
  }
};

type MinimalBuybackProduct = Pick<
  BuybackProduct,
  'image' | 'model' | 'slug' | 'condition' | 'brand' | 'category'
> & { releaseDate: string };

export const convertListingToDevice = (
  listings: Array<MinimalBuybackProduct>,
): Array<StreamlinedDeviceT> =>
  listings.map((listingItem) => ({
    image: listingItem.image,
    text: listingItem.model.split(' - ')[0],
    releaseDate: listingItem.releaseDate,
    link: `${listingItem.slug}`, // self reminder: Do not forget to update buyback logic
  }));

export const mapVariantToIcon = (
  variant: VariantsT,
  subVariant: string,
  category: BuybackProductType,
) => {
  switch (variant) {
    case 'carrier':
      if (subVariant.includes(DeviceCarriers.UNLOCKED)) return unlockedIcon;
      if (subVariant === DeviceCarriers.ATT) return attIcon;
      if (subVariant === DeviceCarriers.VERIZON) return verizonIcon;
      if (subVariant === DeviceCarriers.TMOBILE) return tmobileIcon;

      return networkIcon;
    case 'connectivity':
      if (subVariant === DeviceConnectivity.WIFI) return wifiIcon;
      if (subVariant === DeviceConnectivity.CELLULAR) return cellularIcon;

      return networkIcon;
    case 'cpu':
      return cpuIcon;
    case 'ram':
      return ramIcon;
    case 'watchCaseMaterial':
      return watchCaseMaterialIcon;
    case 'storage':
      if (category === 'watch') return watchSizeIcon;

      return storageIcon;
    case 'screen':
      // Laptops
      if (category === 'laptop') {
        if (subVariant === DeviceConditions.FLAWLESS)
          return laptopScreenFlawlessIcon;
        if (subVariant === DeviceConditions.MINOR) return laptopScreenMinorIcon;
        if (subVariant === DeviceConditions.MODERATE)
          return laptopScreenModerateIcon;

        return laptopScreenCrackedIcon;
      }

      // Watches
      if (category === 'watch') {
        if (subVariant === DeviceConditions.FLAWLESS)
          return watchScreenFlawlessIcon;
        if (subVariant === DeviceConditions.MINOR) return watchScreenMinorIcon;
        if (subVariant === DeviceConditions.MODERATE)
          return watchScreenModerateIcon;

        return watchScreenCrackedIcon;
      }

      // Tablets
      if (category === 'tablet') {
        if (subVariant === DeviceConditions.FLAWLESS)
          return tabletScreenFlawlessIcon;
        if (subVariant === DeviceConditions.MINOR) return tabletScreenMinorIcon;
        if (subVariant === DeviceConditions.MODERATE)
          return tabletScreenModerateIcon;

        return tabletScreenCrackedIcon;
      }

      // Phones
      if (subVariant === DeviceConditions.FLAWLESS)
        return phoneScreenFlawlessIcon;
      if (subVariant === DeviceConditions.MINOR) return phoneScreenMinorIcon;
      if (subVariant === DeviceConditions.MODERATE)
        return phoneScreenModerateIcon;

      return phoneScreenCrackedIcon;

    case 'housing':
      // Laptops
      if (category === 'laptop') {
        if (subVariant === DeviceConditions.FLAWLESS)
          return laptopBackFlawlessIcon;
        if (subVariant === DeviceConditions.MINOR) return laptopBackMinorIcon;
        if (subVariant === DeviceConditions.MODERATE)
          return laptopBackModerateIcon;

        return laptopBackCrackedIcon;
      }

      // Watches
      if (category === 'watch') {
        if (subVariant === DeviceConditions.FLAWLESS)
          return watchBackFlawlessIcon;
        if (subVariant === DeviceConditions.MINOR) return watchBackMinorIcon;
        if (subVariant === DeviceConditions.MODERATE)
          return watchBackModerateIcon;

        return watchBackCrackedIcon;
      }

      // Tablets
      if (category === 'tablet') {
        if (subVariant === DeviceConditions.FLAWLESS)
          return tabletBackFlawlessIcon;
        if (subVariant === DeviceConditions.MINOR) return tabletBackMinorIcon;
        if (subVariant === DeviceConditions.MODERATE)
          return tabletBackModerateIcon;

        return tabletBackCrackedIcon;
      }

      // Phones
      if (subVariant === DeviceConditions.FLAWLESS)
        return phoneBackFlawlessIcon;
      if (subVariant === DeviceConditions.MINOR) return phoneBackMinorIcon;
      if (subVariant === DeviceConditions.MODERATE)
        return phoneBackModerateIcon;

      return phoneBackCrackedIcon;

    default:
      return null;
  }
};

export const generateIsGrid = (variant: VariantsT) =>
  ['screen', 'housing'].includes(variant);

export const generateVariants = (products: DeviceT[]): DeviceVariantT[] => {
  // Initialize sets for unique values
  const storageSet = new Set<string>();
  const carrierSet = new Set<string>();
  const caseMaterialSet = new Set<string>();
  const cpuSet = new Set<string>();
  const ramSet = new Set<string>();
  const connectivitySet = new Set<string>();

  // Iterate over products to populate sets
  products.forEach((product) => {
    // Storage
    if (product.storage) storageSet.add(product.storage);
    // Carrier
    if (product.carrier) carrierSet.add(product.carrier);
    // Case Material
    if (product.watchCaseMaterial)
      caseMaterialSet.add(product.watchCaseMaterial);
    // CPU
    if (product.cpu) cpuSet.add(product.cpu);
    // RAM
    if (product.ram) ramSet.add(product.ram);
    // Connectivity
    if (product.connectivity) connectivitySet.add(product.connectivity);
  });

  const variants: DeviceVariantT[] = [];

  if (storageSet.size > 0)
    variants.push({ variant: 'storage', options: Array.from(storageSet) });

  if (carrierSet.size > 0)
    variants.push({ variant: 'carrier', options: Array.from(carrierSet) });

  if (caseMaterialSet.size > 0) {
    variants.push({
      variant: 'watchCaseMaterial',
      options: Array.from(caseMaterialSet),
    });
  }

  if (cpuSet.size > 0)
    variants.push({ variant: 'cpu', options: Array.from(cpuSet) });

  if (ramSet.size > 0)
    variants.push({ variant: 'ram', options: Array.from(ramSet) });

  if (connectivitySet.size > 0) {
    variants.push({
      variant: 'connectivity',
      options: Array.from(connectivitySet),
    });
  }

  return variants;
};

export const generateSteps = (variants?: DeviceVariantT[]) => {
  const steps =
    (variants?.length ?? MINIMUM_SUB_STEPS) +
    COMMON_VARIANTS.length +
    MAIN_STEPS;

  return steps;
};

export const streamlineCondition = (
  condition: string,
  type: 'housing' | 'screen',
) => {
  switch (condition) {
    case DeviceConditions.CRACKED:
      if (type === 'screen') return `Screen Cracked`;

      return 'Housing Cracked';
    case DeviceConditions.FLAWLESS:
      if (type === 'screen') return `Screen Flawless`;

      return 'Housing Flawless';
    case DeviceConditions.MINOR:
      if (type === 'screen') return `Screen Minor`;

      return 'Housing Minor';
    case DeviceConditions.MODERATE:
      if (type === 'screen') return `Screen Moderate`;

      return 'Housing Moderate';
    default:
      return condition;
  }
};

export const formatPhone = (phone?: string) => {
  if (!phone) return '';

  return phone.startsWith('+1') // Remove +1 from the phone if it exists
    ? phone.slice(2, phone.length)
    : phone;
};

export const mixpanelSummaryProps = (summaryQuote: SummaryQuoteT | null) => ({
  Model: summaryQuote?.model,
  Quote: summaryQuote?.total,
  Storage: summaryQuote?.storage,
  Carrier: summaryQuote?.carrier,
  CPU: summaryQuote?.cpu,
  RAM: summaryQuote?.ram,
  Connectivity: summaryQuote?.connectivity,
  'Watch Case Material': summaryQuote?.watchCaseMaterial,
  'Screen Condition': summaryQuote?.screen,
  'Housing Condition': summaryQuote?.housing,
  'Fully Functional': summaryQuote?.condition,
});
