import { NextRouter } from 'next/router';
import { useEffect } from 'react';
import settings from '@/settings';

const useZendeskWidget = (router: NextRouter, isLauncherHidden: boolean) => {
  useEffect(() => {
    const isUsStore = settings.store === 'reebelo-us';

    if (typeof window === 'undefined' || !document || !window?.zE) return;

    const { pathname } = router;

    if (
      (pathname === '/collections/[handle]' ||
        pathname === '/products/[handle]') &&
      isUsStore
    ) {
      window?.zE('webWidget', 'hide');

      return;
    }

    window?.zE('webWidget', 'show');

    if (document.body.clientWidth > 1024) return;

    switch (pathname) {
      case '/collections/[handle]':
      case '/products/[handle]':
      case '/cart':
        window?.zE('webWidget', 'hide');
        break;

      default:
        window?.zE('webWidget', 'show');
        break;
    }
  }, [router.pathname, isLauncherHidden]);
};

export default useZendeskWidget;
