import axios from 'axios';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import {
  CreateCustomer,
  Customer,
  CustomerAddress,
  CustomerAlias,
} from '@lambda/apis/src/customer/types';
import {
  CustomerAddressType,
  CustomerSubscriptionChannel,
} from '@lambda/apis/src/customer/enum';
import { logoutCustomer } from '../analytics/trackCustomer';
import { logger } from '../logger';
import {
  GoogleResponse,
  UpdateEmailSubscriptionRequest,
  UpdateSmsSubscriptionRequest,
  UseCustomerResult,
} from './types';

const COOKIE_TOKEN = 'auth-token';
const COOKIE_EXPIRY = 'auth-expire';
const COOKIE_USER = 'auth-user';
const COOKIE_ANONYMOUS_ID = 'ajs_anonymous_id';

const useCustomer = (): UseCustomerResult => {
  const [cookies, setCookie, removeCookie] = useCookies([
    COOKIE_TOKEN,
    COOKIE_EXPIRY,
    COOKIE_USER,
    COOKIE_ANONYMOUS_ID,
  ]);

  // Legacy - no longer needed
  if (cookies[COOKIE_EXPIRY]) removeCookie(COOKIE_EXPIRY);

  const initialSessionToken = cookies[COOKIE_TOKEN] || null;
  const initialCustomer = cookies[COOKIE_USER] || null;

  const [customer, setCustomer] = useState<Customer | undefined>(
    initialCustomer,
  );
  const [anonymousId, setAnonymousId] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (cookies.ajs_anonymous_id) {
      logger.info(
        { anonymousId: cookies.ajs_anonymous_id },
        'Setting anonymous id',
      );

      setAnonymousId(cookies.ajs_anonymous_id);
    }
  }, [cookies]);

  const setCustomerData = (c: Customer) => {
    setCookie(COOKIE_USER, c, { path: '/' });
    setCookie(COOKIE_TOKEN, c.accessToken, { path: '/' });
    setCustomer(c);
  };

  const clearCustomerData = () => {
    removeCookie(COOKIE_USER, { path: '/' });
    removeCookie(COOKIE_TOKEN, { path: '/' });
    setCustomer(undefined);
  };

  const login = async (
    email: string,
    password: string,
  ): Promise<Customer | undefined> => {
    try {
      setError(null);
      setLoading(true);

      const response = await axios.post<Customer>('/api/customer/auth', {
        email,
        password,
      });

      if (!response.data?.accessToken) throw new Error('Authentication failed');

      setCustomerData(response.data);

      return response.data;
    } catch (err) {
      const errorMessage = 'Incorrect email or password';

      setError(errorMessage);
      logger.error({ err, errorMessage }, 'Error during login');
    } finally {
      setLoading(false);
    }

    return undefined;
  };

  const loginWithGoogle = async (
    res: GoogleResponse,
  ): Promise<Customer | undefined> => {
    try {
      setLoading(true);

      const response = await axios.post<Customer>('/api/login', res);

      if (!response.data?.reebeloId) throw new Error('Authentication failed');

      setCustomerData(response.data);

      return response.data;
    } catch (err) {
      setError('There was a problem logging in with Google. Please try again.');

      logger.error({ err }, 'Google response error');
    } finally {
      setLoading(false);
    }

    return undefined;
  };

  const logout = async () => {
    await axios.delete('/api/customer/auth').finally(() => {
      clearCustomerData();
      logoutCustomer();
    });
  };

  const forgotPassword = async (email: string): Promise<void> => {
    try {
      await axios.post('/api/customer/auth/forgot', { email });
    } catch (err) {
      logger.error({ err }, 'Error sending forgot password email');
    }
  };

  const resetPassword = async (
    shopifyId: string,
    token: string,
    password: string,
  ): Promise<Customer> => {
    try {
      const { data } = await axios.post<Customer>('/api/customer/auth/reset', {
        shopifyId,
        token,
        password,
      });

      setCustomerData(data);

      return data;
    } catch (err) {
      logger.error({ err }, 'Error resetting password');

      throw err;
    }
  };

  const signup = async (
    customerData: CreateCustomer,
  ): Promise<Customer | undefined> => {
    try {
      setError(null);
      setLoading(true);

      const response = await axios.post<Customer>(
        '/api/customer',
        customerData,
      );

      if (!response.data) throw new Error('Failed to create customer profile');

      return response.data;
    } catch (err) {
      let errorMessage =
        'There was a problem signing up.  Please try again later.';

      if (axios.isAxiosError(err)) {
        const responseData: { code?: number } = err.response?.data || {};

        if (responseData && responseData.code === 409) {
          errorMessage =
            'An account under this email already exists. Try resetting your password.';
        }
      }

      setError(errorMessage);
      logger.error({ err, errorMessage }, 'Error creating customer profile');
    } finally {
      setLoading(false);
    }

    return undefined;
  };

  const updateAddress = async (
    type: CustomerAddressType,
    address: CustomerAddress,
  ): Promise<Customer | undefined> => {
    try {
      setLoading(true);

      const response = await axios.put<Customer>(
        `/api/customer/address?type=${type}`,
        address,
      );

      if (!response.data) throw new Error('Failed to update address');

      setCustomerData(response.data);
    } catch (err) {
      setError(
        'There was a problem updating your address. Please try again later.',
      );

      logger.error({ err }, 'Error updating address');
    } finally {
      setLoading(false);
    }

    return undefined;
  };

  const updateEmailSubscription = async (
    req: UpdateEmailSubscriptionRequest,
  ): Promise<void> => {
    try {
      const channel = CustomerSubscriptionChannel.EMAIL;

      await axios.post('/api/customer/subscribe', {
        ...req,
        channel,
        anonymousId,
      });
    } catch (err) {
      logger.error({ err, req }, 'Error updating email subscription');
    }
  };

  const updateSmsSubscription = async (
    req: UpdateSmsSubscriptionRequest,
  ): Promise<void> => {
    try {
      const channel = CustomerSubscriptionChannel.SMS;

      await axios.post('/api/customer/subscribe', {
        ...req,
        channel,
        anonymousId,
      });
    } catch (err) {
      logger.error({ err, req }, 'Error updating sms subscription');
    }
  };

  const createAlias = async (
    customerId: string,
    alias: CustomerAlias,
  ): Promise<void> => {
    try {
      await axios.post(`/api/customer/alias`, {
        customerId,
        alias,
      });
    } catch (err) {
      logger.error({ err }, 'Error creating customer alias');
    }
  };

  const fetchCustomerData = async (): Promise<Customer | undefined> => {
    try {
      if (customer) return undefined;

      setLoading(true);

      const response = await axios.get<Customer>('/api/customer/me');

      if (!response.data) throw new Error('User not found');

      return response.data;
    } catch (err) {
      logger.error({ err }, 'Error fetching customer data');
      clearCustomerData();
    } finally {
      setLoading(false);
    }

    return undefined;
  };

  const verifyCookies = async (): Promise<void> => {
    logger.info({ initialSessionToken, initialCustomer }, 'verifying cookies');

    if (initialSessionToken && !initialCustomer) {
      const customerData = await fetchCustomerData();

      if (!customerData) return;

      setCustomerData(customerData);
    }
  };

  useEffect(() => {
    verifyCookies();
  }, []);

  return {
    // State
    customer,
    anonymousId,
    loading,
    error,

    // Api
    login,
    loginWithGoogle,
    logout,
    forgotPassword,
    resetPassword,
    signup,
    updateAddress,
    fetchCustomerData,
    updateEmailSubscription,
    updateSmsSubscription,
    createAlias,
  };
};

export default useCustomer;
