import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { analytics, shouldTrack } from './segment';
import { useCustomerContext } from '@/lib/customer/CustomerContext';
import { identifyCustomer } from '../../trackCustomer';
import { logger } from '@/lib/logger';

export default function SegmentAnalytics() {
  const router = useRouter();
  const pathname = router.asPath;
  const searchParams = router.query;
  const { customer } = useCustomerContext();

  useEffect(() => {
    if (analytics) (window as any).analytics = analytics;

    return () => {
      (window as any).analytics = undefined;
    };
  }, []);

  useEffect(() => {
    if (!shouldTrack()) {
      logger.debug({ pathname, searchParams }, 'not tracking segment page');

      return;
    }

    logger.debug({ pathname, searchParams }, 'handling segment page');

    analytics.page();
  }, [pathname, searchParams]);

  useEffect(() => {
    logger.debug({ customer }, 'handling segment analytics for customer');

    if (customer) identifyCustomer(customer);
  }, [customer]);

  return null;
}
