import axios, { AxiosInstance } from 'axios';
import { useCallback } from 'react';
import { BUYBACK_BASE_URL } from '@lambda/gateways/src/urls';
import { ReebeloStoreT } from '@lambda/reebelo';
import memoizee from 'memoizee';
import { AxiosRequestConfig } from '@lambda/commons/apis/axios';
import { Product, BuybackQuoteUS, VendorDetails } from '../common';
import { BuybackCustomer } from '@/lib/buyback/types';

export type DeviceDetails = Product & { image: string; slug: string };

export type VendorQuoteT = { vendor: VendorDetails; price: number };

export type ShipperAddress = {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
};

export type Customer = {
  firstName: string;
  lastName: string;
  address: ShipperAddress;
};

export type ShippingLabelInput = {
  store: ReebeloStoreT;
  orderId: string;
  vendorAddress: string;
  token?: string;
  customer: BuybackCustomer;
};
type ApiHeaders = AxiosRequestConfig['headers'];

const api: AxiosInstance = axios.create({ baseURL: BUYBACK_BASE_URL });

export const setHeader = (key: string, value: string) => {
  const headers: ApiHeaders = { ...api.defaults.headers };

  headers[key] = value;
  api.defaults.headers = headers;
};

const useCb = <T, R extends Array<any>>(fn: (...args: R) => T) =>
  useCallback(fn, []);

export const useApi = () => {
  const getDeviceBySlug = memoizee(
    useCb((deviceSlug: string) =>
      api.get<DeviceDetails[]>(`/products/${deviceSlug}`),
    ),
    { promise: true },
  );
  const postBuybackQuote = useCb((quote: BuybackQuoteUS) =>
    api.post<Record<string, any>>('/order/us', { ...quote }),
  );
  const getProductQuote = useCb(
    (
      productId: string,
      deviceIssues: Record<string, boolean>,
      batteryHealth: string,
    ) =>
      api.post<VendorQuoteT[]>('/quote/us', {
        productId,
        deviceIssues,
        batteryHealth,
      }),
  );
  const generateShippingLabelToken = useCb((input: ShippingLabelInput) =>
    api.post(`/order/us/shipping-label-token`, {
      input,
    }),
  );
  const generateShipment = useCb((input: ShippingLabelInput) =>
    api.post(`/order/us/shipment`, {
      input,
    }),
  );
  const generateShippingLabel = useCb((token: string) =>
    api.get(`/order/us/shipping-label/${token}`),
  );

  return {
    getDeviceBySlug,
    postBuybackQuote,
    getProductQuote,
    generateShippingLabel,
    generateShipment,
    generateShippingLabelToken,
  };
};
