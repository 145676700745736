import { Dispatch, SetStateAction } from 'react';
import { BuybackProductType } from '@lambda/apis/src/dynamoDb/types';
import { BuybackUSConfig } from '@/settings/configs/buyback/us/type';
import { BuybackQuoteUS } from '@/components/buyback/common';

export type ConfigT = {
  device: BuybackUSConfig;
  step: number;
};

export type StreamlinedDeviceT = {
  image: string;
  text: string;
  link?: string;
};

export type TradeInT = {
  order: BuybackOrder;
  shippingLabel: string;
};

export type DeviceT = {
  'basePrice-used': number;
  bestVendorPrice: number;
  brand: string;
  carrier: string;
  category: BuybackProductType;
  condition: string;
  deductions: {
    housingMinor: { type: string; value: number };
    screenMinor: { type: string; value: number };
  };
  deviceSpecificIssues: { warrantyIsLessThan2Months: boolean };
  displayOrder: number;
  hasDeductions: boolean;
  image: string;
  key: string;
  'lsi-2-sk': string;
  'lsi-sk': string;
  model: string;
  partitionKey: string;
  pimPSKU: string;
  productId: string;
  rid: string;
  slug: string;
  'sort-key': string;
  updatedAt: string;
  variantId: string;
  vendorSlug: string;
} & Partial<Record<VariantsT, string>>;

export enum PageType {
  NAVIGATION = 'navigation',
  BRAND = 'brand',
  MODEL = 'model',
  VARIANT = 'variant',
  VALUATION = 'valuation',
  INFO = 'info',
  PAYOUT = 'payout',
}

// Pages Related Types
export type SellDevicePageT = {
  device: BuybackUSConfig;
  pageType: PageType;
  step: number;
};

export type DevicePageT = {
  device: BuybackUSConfig;
  variants: DeviceVariantT[];
  devices: DeviceT[];
};

export type ValuationPageT = {
  device: BuybackUSConfig;
};

export type PersonalInfoPageT = {
  device: BuybackUSConfig;
};

export type PayoutPageT = {
  device: BuybackUSConfig;
};

export type SellDeviceProps = ConfigT & {
  handle: string;
  pageType: PageType;
  variants: DeviceVariantT[];
  getQuote: boolean;
  devices: DeviceT[];
};

export type LayoutProps = {
  device: BuybackUSConfig;
  pageType: PageType;
  step?: number;
  steps?: number;
  subStep?: number;
  children: JSX.Element;
};

// Device Related Types
export type VariantsT =
  | 'storage'
  | 'carrier'
  | 'watchCaseMaterial'
  | 'cpu'
  | 'ram'
  | 'connectivity'
  | 'screen'
  | 'housing'
  | 'condition';

export type DeviceVariantT = { variant: VariantsT; options: string[] };

export type SelectedVariantsT = Partial<Record<VariantsT, string>>;

// Enums
export enum PersonalInfo {
  LOGIN = 'login',
  REGISTER = 'register',
  INFO = 'info',
}

export enum DeviceConditions {
  'FLAWLESS' = 'Flawless',
  'MINOR' = 'Minor Scratches',
  'MODERATE' = 'Moderate Scratches',
  'CRACKED' = 'Cracked or Chipped',
}

export enum DeviceCarriers {
  'UNLOCKED' = 'Unlocked',
  'ATT' = 'AT&T',
  'VERIZON' = 'Verizon',
  'TMOBILE' = 'T-Mobile',
}

export enum DeviceConnectivity {
  'WIFI' = 'WiFi',
  'CELLULAR' = 'Cellular',
}

// Context Related Types
export type QuoteT = (DeviceT & { isAccepted: boolean }) | null;

export type UseBuybackResult = {
  finalQuote: BuybackQuoteUS | null;
  updateFinalQuote: (update: Record<string, any>) => void;
  quote: QuoteT;
  setQuote: Dispatch<SetStateAction<QuoteT>>;
  resetQuote: () => void;
  isLoading: boolean;
  summaryQuote: SummaryQuoteT | null;
  setSummaryQuote: Dispatch<SetStateAction<SummaryQuoteT | null>>;
  includesTradeIn: boolean;
};

export type SummaryQuoteT = {
  image: string;
  model: string;
  total: number;
} & Partial<Record<VariantsT, string>>;

export type BuybackShippingAddress = {
  firstName: string;
  lastName: string;
  country: string;
  city: string;
  phone: string;
  address1: string;
  address2: string;
  postalCode: string;
  state: string;
};

export type BuybackCustomer = {
  id: string;
  phone: string;
  subscribed: boolean;
  token: string;
  firstName: string;
  lastName: string;
  email: string;
  address: {
    line1: string;
    line2?: string;
    city: string;
    state: string;
    postalCode: string;
    country: 'US';
  };
};

export type CollectionDetailsT = {
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  mobile: string;
  suburb: string;
  postCode: number;
  state: string;
};

export type BuybackShippingAddressErrors = Array<
  keyof BuybackShippingAddress | 'email' | 'birthdate'
>;

export type BuybackOrder = {
  key?: string | undefined;
  shippingDetails: {
    token: string;
    totalCharges: number;
    trackingNumber: string;
  };
  collection: {
    date: string;
    time: string;
    method: string;
    outlet: string;
    timestamp: number;
  };
  condition: {
    'Housing Minor'?: boolean;
    'Screen Minor'?: boolean;
    'Battery Fair'?: boolean;
    Box?: boolean;
    'Charger / Adapter'?: boolean;
  };
  conditionType: 'used' | 'new';
  customer: {
    fullName: string;
    address: string;
    email: string;
    phone: string;
  };
  device: {
    model: string;
    category: string;
    storage: string;
    cpu: string;
    gpu: string;
    carrier: string;
    brand: string;
  };
  paymentMethod: {
    name: string;
    paypal?: {
      amount: {
        type: string;
        value: string;
      };
      creditorAccount: {
        identification: string;
      };
      creditor: {
        name: string;
        type: string;
        postalAddress: {
          addressLine: [string];
          city: string;
          country: string;
        };
      };
      creditorAgent: {
        branch: {
          identification: string;
          name: string;
        };
        institution: {
          identification: string;
          name: string;
        };
      };
      paymentMethod: string;
      paymentType: {
        serviceLevel: string;
      };
      paymentIdentification: {
        endToEndIdentification: string;
        instructionIdentification: string;
      };
      requestedExecutionDate: string;
      paypalEmail: string;
      paypalMobile: string;
    };
    zelle?: {
      amount: {
        type: string;
        value: string;
      };
      creditorAccount: {
        identification: string;
      };
      creditor: {
        name: string;
        type: string;
        postalAddress: {
          addressLine: [string];
          city: string;
          country: string;
        };
      };
      creditorAgent: {
        branch: {
          identification: string;
          name: string;
        };
        institution: {
          identification: string;
          name: string;
        };
      };
      paymentMethod: string;
      paymentType: {
        serviceLevel: string;
      };
      paymentIdentification: {
        endToEndIdentification: string;
        instructionIdentification: string;
      };
      requestedExecutionDate: string;
      zelleEmail: string;
      zelleMobile: string;
    };
  };
  'lsi-2-sk': string;
  'lsi-sk': string;
  orderHandle: string;
  orderId: string;
  paid?: string;
  'partition-key': 'ORDER';
  productId: string;
  quote: number;
  'sort-key': string;
  status: string;
  submissionDate: string;
  vendor: string;
  vendorName: string;
  screenCondition: string;
  housingCondition: string;
  deviceFunctional: 'Yes' | 'No';
};

export enum TradeInStep {
  Loading = 0,
  SelectDevice = 1,
  SelectVariant = 2,
  Valuation = 3,
  PayoutInformation = 4,
  Summary = 5,
}

export type QuotePayoutInformationT = {
  paymentMethod: string;
  paymentDetails: {
    amount: {
      type: string;
      value: string;
    };
    creditorAccount: {
      identification: string;
    };
    creditor: {
      name: string;
      type: string;
      postalAddress: {
        addressLine: string[];
        city: string;
        country: string;
      };
    };
    creditorAgent: {
      branch: {
        identification: string;
        name: string;
      };
      institution: {
        identification: string;
        name: string;
      };
    };
    paymentMethod: string;
    paymentType: {
      serviceLevel: string;
    };
    paymentIdentification: {
      endToEndIdentification: string;
      instructionIdentification: string;
    };
    requestedExecutionDate: string;
  };
};
