import { REEBELO_DOMAINS, ReebeloStoreT } from '@lambda/reebelo';
import { NextRouter } from 'next/router';
import { useMemo } from 'react';
// Currently the childPageCanonical is only used for URLs of child variants of PSKUs, in the US store,
// for the three major service providers. T-Mobile, Verizon, AT&T, and Unlocked.

const useGetChildPageCanonicalUrl = (
  router: NextRouter,
  store: ReebeloStoreT,
) =>
  useMemo(() => {
    const handle = router.query.handle as string;
    const { serviceProvider } = router.query;

    if (!serviceProvider || !handle) return undefined;

    const validCarriers = ['T-Mobile', 'Verizon', 'AT&T'];
    const isValidCarrier = validCarriers.includes(String(serviceProvider));
    const mvno = router.query.mvno as string;

    const isUSStore = store === 'reebelo-us';
    const isDevStore = store === 'reebelo-dev';

    if (!isValidCarrier) return undefined;
    if (!(isUSStore || isDevStore)) return undefined;
    if (mvno) return undefined;

    return `https://${
      REEBELO_DOMAINS[store]
    }/collections/${handle}?serviceProvider=${
      serviceProvider === 'AT&T' ? 'AT%26T' : serviceProvider
    }`;
  }, [router.query.handle, router.query.mvno, router.query.serviceProvider]);

export default useGetChildPageCanonicalUrl;
