import { CartLine } from '@lambda/commons/apis/shopify/types/storefront';
import { extractTags } from '@lambda/reebelo/src/tagHelpers';
import { ReebeloConditions } from '@lambda/reebelo';
import { BABY_CATEGORIES } from '@lambda/reebelo/src/commonHelpers';
import shopify from '@/lib/shopify';
import settings from '@/settings';
import { CartItemAttribute, UpdateCartParams } from './use-cart';
import {
  POWER_TOOL_CATEGORIES,
  SPORT_FITNESS_CATEGORIES,
} from '@/settings/configs';

const getLastestProductQuantityUsingHandle = async (handle: string) => {
  const response = await shopify.fetch({
    query: `query ($handle: String!) {
              product(handle: $handle) {
                variants(first:1){ edges { node {
                  id availableForSale quantityAvailable
                } } }
              }
            }`,
    variables: { handle },
  });
  const { product } = response;

  return {
    quantityAvailable:
      product?.variants?.edges?.[0]?.node?.quantityAvailable || 0,
    availableForSale: product?.variants?.edges?.[0]?.node?.availableForSale,
  };
};

export const SHOPIFY_CART_QUERY = `
id checkoutUrl
attributes { key value }
discountCodes { code applicable }
estimatedCost { totalAmount { amount } subtotalAmount { amount } totalTaxAmount { amount } }
lines(first: 10) { edges { node {
  id quantity attributes { key value }
  merchandise { ... on ProductVariant {
      id sku quantityAvailable title
      image { url }
      priceV2 { amount }
      compareAtPriceV2 {amount}
      product { title, tags, vendor, onlineStoreUrl, handle, productType}
  } }
} } }`;

export const deleteCartLines = async (
  lineIds: Array<string>,
  cartId: string,
) => {
  const response = await shopify.fetchSafe({
    query: `mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
                cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
                  cart { ${SHOPIFY_CART_QUERY} }
                  userErrors { field message }
                }
              }`,
    variables: { cartId, lineIds },
  });

  return response?.cartLinesRemove.cart;
};

export const updateCartLines = async (
  lines: UpdateCartParams,
  cartId: string,
) => {
  const response = await shopify.fetchSafe({
    query: `mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
              cartLinesUpdate(cartId: $cartId, lines: $lines) {
                cart { ${SHOPIFY_CART_QUERY} }
                userErrors { field message }
              }
            }`,
    variables: { cartId, lines },
  });

  return response?.cartLinesUpdate.cart;
};

export const findOutOfStockCartLineItems = async (lineItems: CartLine[]) => {
  const toBeRemovedCartLineItems: string[] = [];

  const unavailableLineItems = await Promise.all(
    lineItems.map(async (lineItem) => {
      const { title } = lineItem.merchandise.product;
      const { quantity } = lineItem;

      if (
        title.toLowerCase().indexOf('reebelocare') > -1 ||
        title.toLowerCase().indexOf('express shipping') > -1 ||
        title.toLowerCase().indexOf("reebelo's gift card") > -1
      )
        return undefined;

      const { quantityAvailable, availableForSale } =
        await getLastestProductQuantityUsingHandle(
          lineItem.merchandise.product.handle,
        );

      if (quantityAvailable - quantity < 0 || !availableForSale) {
        return {
          id: lineItem.id,
          sku: lineItem.merchandise.sku,
          vendor: lineItem.merchandise.product.vendor,
        };
      }

      return undefined;
    }),
  );

  /*
   * RT-4875
   * Check for the merchandise to be removed
   */
  if (unavailableLineItems.filter(Boolean).length > 0) {
    unavailableLineItems.forEach((unavailableLineItem) => {
      if (unavailableLineItem != null) {
        // Add the to be removed merchandise into the array
        toBeRemovedCartLineItems.push(unavailableLineItem.id);
        const offerKey = `${settings.store}#${unavailableLineItem.vendor}#${unavailableLineItem.sku}`;

        // Find out reebelocare / express shipping item to be removed
        lineItems.forEach((lineItem) => {
          const { title } = lineItem.merchandise.product;

          if (
            title.toLowerCase().indexOf('reebelocare') > -1 ||
            title.toLowerCase().indexOf('express shipping') > -1
          ) {
            const { attributes } = lineItem;
            const tobeRemove = attributes.some(
              (attribute: CartItemAttribute) =>
                attribute.key === 'ID' && attribute.value === offerKey,
            );

            if (tobeRemove) toBeRemovedCartLineItems.push(lineItem.id);
          }
        });
      }
    });
  }

  return toBeRemovedCartLineItems;
};

export const generateCartWarrantyText = (lineItems: CartLine[]) => {
  const checkItemCategory = (
    categories: string[],
    method: 'some' | 'every' = 'some',
  ) =>
    lineItems[method]((item) => {
      const { tags } = item.merchandise.product;
      const extracted = extractTags(tags);

      return categories.includes(extracted.category || '');
    });

  const checkItemCondition = (
    condition: string,
    method: 'some' | 'every' = 'some',
  ) =>
    lineItems[method]((item) => {
      const { tags } = item.merchandise.product;
      const extracted = extractTags(tags);

      return extracted.condition === condition;
    });

  const allItemsAreBrandNew = checkItemCondition(
    ReebeloConditions.BrandNew,
    'every',
  );

  const allItemsAreBaby = checkItemCategory(BABY_CATEGORIES, 'every');
  const allItemsArePowerTool = checkItemCategory(
    POWER_TOOL_CATEGORIES,
    'every',
  );
  const allItemsAreSportsAndFitness = checkItemCategory(
    SPORT_FITNESS_CATEGORIES,
    'every',
  );

  const someItemsAreBrandNew = checkItemCondition(ReebeloConditions.BrandNew);
  const someItemsAreBabyItems = checkItemCategory(BABY_CATEGORIES);
  const someItemsArePowerTools = checkItemCategory(POWER_TOOL_CATEGORIES);
  const someItemsAreSportsAndFitness = checkItemCategory(
    SPORT_FITNESS_CATEGORIES,
  );

  if (!['reebelo-us', 'quista'].includes(settings.store))
    return `${settings.warranty_month} Months Warranty`;

  if (allItemsAreBrandNew) return '12 Months Warranty';
  if (allItemsAreBaby || allItemsArePowerTool) return '3 Months Warranty';
  if (allItemsAreSportsAndFitness) return '1 Month Warranty';
  if (
    someItemsAreBrandNew ||
    someItemsAreBabyItems ||
    someItemsArePowerTools ||
    someItemsAreSportsAndFitness
  )
    return 'Up to 12 Months Warranty';

  return settings.store === 'quista'
    ? '3 months warranty'
    : `${settings.warranty_month} Months Warranty`;
};
