import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import settings from '@/settings';
import { useCustomerContext } from '../customer/CustomerContext';

const SPLIT_KEY = process.env.NEXT_PUBLIC_SPLIT_KEY;

export const FeatureFlagProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { customer, anonymousId } = useCustomerContext();

  const config: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: SPLIT_KEY || '',
      key: customer?.reebeloId || anonymousId || 'unknown',
    },
  };

  const attributes = {
    store: settings.store,
  };

  return (
    <>
      {SPLIT_KEY ? (
        <SplitFactoryProvider config={config} attributes={attributes}>
          {children}
        </SplitFactoryProvider>
      ) : (
        children
      )}
    </>
  );
};
