import { useEffect, useContext } from 'react';
import mixpanel from 'mixpanel-browser';
import { AppCtx } from '../pages/_app';
import { logger } from './logger';

// Mixpanel tokens is not secret
// https://developer.mixpanel.com/reference/project-token
const PROJ_TOKENS = {
  dev: '747c41d8f6960495d17ed5aa835f7e99',
  prod: '2517674efe510385c15d3443c5228912',
};

export default function setupMixpanel(setMixpanelReady: () => void) {
  const isProd =
    process.env.NEXT_PUBLIC_STAGE === 'prod' || process.env.STAGE === 'prod';
  const projectToken = isProd ? PROJ_TOKENS.prod : PROJ_TOKENS.dev;

  mixpanel.init(projectToken, {
    debug: !isProd,
    ignore_dnt: true,
  });
  setMixpanelReady();
}

export function useMixpanelPageViewEvent({
  details,
  pageViewedEventName,
}: {
  pageViewedEventName: string;
  details?: Record<string, any>;
}) {
  const { isMixPanelReady } = useContext(AppCtx);

  useEffect(() => {
    if (isMixPanelReady === true && pageViewedEventName != null)
      mixpanel.track(pageViewedEventName, details);
  }, [isMixPanelReady, pageViewedEventName]);
}

export function triggerMixpanelEvent({
  eventName,
  props,
  options = {},
  callback,
}: {
  eventName: string;
  props: Record<string, any>;
  options?: { transport?: 'xhr' | 'sendBeacon'; send_immediately?: boolean };
  callback?: () => void;
}) {
  try {
    mixpanel.track(eventName, props, options, callback);
  } catch (error) {
    logger.error({ error }, 'error tracking mixpanel event');
  }
}

export function useGetMixpanelDistinctId() {
  const { isMixPanelReady } = useContext(AppCtx);

  if (isMixPanelReady) {
    const mixpanelDistinctId = mixpanel.get_distinct_id();

    return { mixpanelDistinctId };
  }

  return { mixpanelDistinctId: null };
}

export { mixpanel };
