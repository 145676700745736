/* eslint-disable no-underscore-dangle */
const STAGE = process.env.STAGE as 'prod' | 'staging' | 'dev';
const isOffline = process.env.IS_OFFLINE;

let endpoints = {
  CONNECTORS_BASE_URL: '',
  PIM_BASE_URL: '',
  BUYBACK_BASE_URL: '',
  COBALT_OPS_BASE_URL: '',
  COBALT_FINANCE_BASE_URL: '',
  COBALT_INVENTORY_OFFERS_BASE_URL: '',
  COBALT_INVENTORY_UPPPRICER_BASE_URL: '',
  COBALT_ORDER_REFUNDS_BASE_URL: '',
  COBALT_ORDER_RETURNS_BASE_URL: '',
  US_CONNECTORS_BASE_URL: '',
};

// We use a switch so it will bundle properly across all stacks
switch (STAGE) {
  case 'prod':
    endpoints = {
      CONNECTORS_BASE_URL: isOffline
        ? `http://localhost:2729/${STAGE}`
        : 'https://a.reebelo.com/cobalt',
      PIM_BASE_URL: isOffline
        ? `http://localhost:2728/${STAGE}`
        : 'https://a.reebelo.com/pim',
      BUYBACK_BASE_URL: 'https://api.reebelo.com/buyback',
      COBALT_OPS_BASE_URL: isOffline
        ? `http://localhost:2733/${STAGE}`
        : 'https://a.reebelo.com/ops',
      COBALT_FINANCE_BASE_URL: isOffline
        ? `http://localhost:2726/${STAGE}`
        : 'https://a.reebelo.com/finance',
      COBALT_INVENTORY_OFFERS_BASE_URL: isOffline
        ? `http://localhost:2725/${STAGE}`
        : 'https://a.reebelo.com/inventory-offers',
      COBALT_INVENTORY_UPPPRICER_BASE_URL: isOffline
        ? `http://localhost:2727/${STAGE}`
        : 'https://a.reebelo.com/inventory-uppricer',
      COBALT_ORDER_REFUNDS_BASE_URL: isOffline
        ? `http://localhost:2724/${STAGE}`
        : 'https://a.reebelo.com/order-refunds',
      COBALT_ORDER_RETURNS_BASE_URL: isOffline
        ? `http://localhost:2724/${STAGE}`
        : 'https://a.reebelo.com/order-returns',
      US_CONNECTORS_BASE_URL: isOffline
        ? `http://localhost:2755/${STAGE}`
        : `https://us-marketing.reebelo.us`,
    };
    break;
  case 'staging':
    endpoints = {
      CONNECTORS_BASE_URL: isOffline
        ? `http://localhost:2729/${STAGE}`
        : 'https://a.reebelo.blue/cobalt',
      PIM_BASE_URL: isOffline
        ? `http://localhost:2728/${STAGE}`
        : 'https://a.reebelo.blue/pim',
      BUYBACK_BASE_URL: isOffline
        ? `http://localhost:2727/${STAGE}`
        : 'https://api.reebelo.blue',
      COBALT_OPS_BASE_URL: isOffline
        ? `http://localhost:2733/${STAGE}`
        : 'https://a.reebelo.blue/ops',
      COBALT_FINANCE_BASE_URL: isOffline
        ? `http://localhost:2726/${STAGE}`
        : 'https://a.reebelo.blue/finance',
      COBALT_INVENTORY_OFFERS_BASE_URL: isOffline
        ? `http://localhost:2725/${STAGE}`
        : 'https://a.reebelo.blue/inventory-offers',
      COBALT_INVENTORY_UPPPRICER_BASE_URL: isOffline
        ? `http://localhost:2727/${STAGE}`
        : 'https://a.reebelo.blue/inventory-uppricer',
      COBALT_ORDER_REFUNDS_BASE_URL: isOffline
        ? `http://localhost:2724/${STAGE}`
        : 'https://a.reebelo.blue/order-refunds',
      COBALT_ORDER_RETURNS_BASE_URL: isOffline
        ? `http://localhost:2724/${STAGE}`
        : 'https://a.reebelo.blue/order-returns',
      US_CONNECTORS_BASE_URL: isOffline
        ? `http://localhost:2755/${STAGE}`
        : `https://us-marketing.reebelo.blue`,
    };
    break;
  case 'dev':
    endpoints = {
      CONNECTORS_BASE_URL:
        isOffline && STAGE === 'dev'
          ? `http://localhost:2729/${STAGE}`
          : `${process.env.CONNECTOR_SERVICE_ENDPOINT}`,
      PIM_BASE_URL:
        process.env.NEXT_PUBLIC_STORE && process.env.USE_LOCAL_PIM == null // in v2 stacks, we default to staging PIM to avoid dev to have to run local PIM
          ? 'https://a.reebelo.blue/pim'
          : `http://localhost:2728/${STAGE}`,
      BUYBACK_BASE_URL: `http://localhost:2727/${STAGE}`,
      COBALT_OPS_BASE_URL: `http://localhost:2733/${STAGE}`,
      US_CONNECTORS_BASE_URL: `http://localhost:2755/${STAGE}`,
      COBALT_FINANCE_BASE_URL: `http://localhost:2726/${STAGE}`,
      COBALT_INVENTORY_OFFERS_BASE_URL: `http://localhost:2725/${STAGE}`,
      COBALT_INVENTORY_UPPPRICER_BASE_URL: `http://localhost:2727/${STAGE}`,
      COBALT_ORDER_REFUNDS_BASE_URL: `http://localhost:2724/${STAGE}`,
      COBALT_ORDER_RETURNS_BASE_URL: `http://localhost:2723/${STAGE}`,
    };
    break;
  default:
    break;
}

export const {
  CONNECTORS_BASE_URL,
  PIM_BASE_URL,
  BUYBACK_BASE_URL,
  COBALT_OPS_BASE_URL,
  COBALT_FINANCE_BASE_URL,
  COBALT_INVENTORY_OFFERS_BASE_URL,
  COBALT_INVENTORY_UPPPRICER_BASE_URL,
  COBALT_ORDER_REFUNDS_BASE_URL,
  COBALT_ORDER_RETURNS_BASE_URL,
  US_CONNECTORS_BASE_URL,
} = endpoints;
