import { useState, useEffect } from 'react';
import {
  BrazeFeatureFlagT,
  getSubscribeFF,
} from '@/components/commons/brazeUtils';
import { logger } from './logger';

const useSubscribeFF = (featureFlagId: string) => {
  const [value, setValue] = useState<BrazeFeatureFlagT>({ enabled: null });

  useEffect(() => {
    const getSubscription = async () => {
      try {
        const res = await getSubscribeFF(featureFlagId);

        setValue(res);
      } catch (error) {
        logger.error({ featureFlagId, error }, 'Error fetching Feature Flag');
      }
    };

    getSubscription();
  }, [featureFlagId]);

  return value;
};

export default useSubscribeFF;
